.thank_you {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.thank_you .main {
    max-width: max-content;
}
