* {
    font-family: Poppins, Arial, sans-serif;
}

body {
    padding: 0;
    margin: 0;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.app {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.page {
    display: flex;
    padding: 32px;
    flex-grow: 1;
}

.main {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
    max-width: 1024px;
    margin: 0 auto;
}

div.section {
    margin: 16px 0;
}

div.title {
    font-size: 20pt;
    font-weight: 500;
}

div.paragraph {
    max-width: 1024px;
}

div.section div.title {
    margin-bottom: 16px;
}

div.link > *, div.link > *:visited {
    text-decoration: none;
}
