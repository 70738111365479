div.photo_section {
    display: flex;
    max-width: 1024px;
    gap: 32px;
    padding-top: 32px;
}

div.photo_section img {
    max-height: 256px;
    max-width: 386px;
}

div.photo_section .image_container {
    background-color: #111111;
    max-height: 256px;
    max-width: 386px;
}

div.subtitle {
    margin-bottom: 16px;
    font-size: 16pt;
}

div.photo_section div.section {
    margin-top: 0;
    display: flex;
    flex-direction: column;
}

@media only screen
and (max-width: 600px) {
    div.photo_section {
        flex-wrap: wrap;
        gap: 0;
    }

    div.photo_section img {
        height: calc(80vw * (256 / 386));
        width: 80vw;
    }

    div.photo_section .image_container {
        background-color: #111111;
        height: calc(80vw * (256 / 386));
        width: 80vw;
    }
}
