form {
    display: flex;
    flex-direction: column;
    max-width: 512px;
    min-width: 256px;
}

.form_section {
    width: 100%;
    max-width: 1024px;
}

input {
    border-radius: 8px;
    border: 2px solid #000000;
    height: 32px;
    padding-left: 16px;
    font-weight: 500;
}

.message {
    border-radius: 8px;
    border: 2px solid #000000;
    height: 64px;
    padding-left: 16px;
    padding-top: 16px;
    font-weight: 500;
}

select {
    border-radius: 8px;
    border: 2px solid #000000;
    height: 38px;
    padding-left: 16px;
    font-weight: 500;
}

form > * {
    margin: 8px 0;
}

input.submit {
    border-radius: 8px;
    border: none;
    font-weight: 600;
    font-size: 12pt;
    background-color: #004aadff;
    color: #ffffff;
}

input.submit:hover {
    cursor: pointer;
    background-color: #004aadcc;
}

button {
    font-weight: 600;
    height: 32px;
    padding: 0 24px;
    border-radius: 8px;
    font-size: 12pt;
    border: none;
    background-color: #004aadff;
    color: #ffffff;
}

button:hover {
    cursor: pointer;
    background-color: #004aadcc;
}
